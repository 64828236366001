<template>
    <div class="bg-white w-full">
        <AlertBanner :page="orgOwnerPage" :slug="orgOwnerSlug"/>
    </div>
</template>

<script>
import AlertBanner from '../../../views/components/shop/AlertBanners.vue';


export default {
    components: {
        AlertBanner
    },
    data: () => ({
        orgOwnerSlug: "",
        orgOwnerPage: ""
    }),
    methods: {
        getPage(pageName) {
            switch (pageName) {
                case "OrgOwnerOrderCenter":
                    this.orgOwnerPage = "homepage"
                    break;
                case "OrgOwnerOrderCenterCategoryPage":
                    this.orgOwnerPage = "category"
                    break;
                // case "OrgOwnerOrderCenterCategory_Page":
                //     this.orgOwnerPage = "category"
                //     break;
                case "OrgOwnerOrderCenterBrandPage":
                    this.orgOwnerPage = "brand"
                    break;
                case "OrgOwnerProductDetail":
                    this.orgOwnerPage = "productDetail"
                    break;
                case "OrgOwnerProductCheckout":
                    this.orgOwnerPage = "checkout"
                    break;
                default:
                    break;
            }
        }
    },
    watch: {
        "$route.name": {
            handler(newVal, oldVal) {
                if(newVal !== oldVal){
                    this.getPage(this.$route.name);
                }
            },
            immediate: true
        },
        "$route.params.slug": {
            handler(newVal, oldVal) {
                if(newVal !== oldVal){
                    this.orgOwnerSlug = newVal 
                }
            }
        }
    },
    created() {
        this.orgOwnerSlug = this.$route.params.slug
        this.getPage(this.$route.name);
    }
}

</script>
